import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";

import { useEffect, useState } from 'react';
import './CSS/icons.css'
import './CSS/App.css';
import './CSS/GameList.css'
import './CSS/GameDisplay.css'
import './CSS/MemoryCard.css'
import './CSS/Scrollbar.css'
import './CSS/Colours.css'
import './CSS/NewGame.css'
import './CSS/HomePage.css'
import './CSS/GameQueue.css'
import './CSS/CurrentlyPlaying.css'
import './CSS/Error.css'



import Home from './pages/home/Home';
import MemoryCard from "./pages/MemoryCard/MemoryCard";
import Game from "./pages/Game/Game";

import Login from "./pages/home/Login";
import { gameFullInfoApiCall, gamesListApiCall, getGameStatsApiCall, queueListApiCall } from "./api/MemoryCard";
import { loginApiCall } from "./api/Login";
import DefaultFallBack from "./pages/Error/DefaultFallBack";
import NotFound from "./pages/Error/NotFound";



function App() {
  const [loggedIn,  setLoggedIn] = useState(false)

  const homePageLoader = async () => {
    let data = {}
    data["currentlyPlaying"] = await gamesListApiCall("currently_playing")
    data["gameQueue"] = await  queueListApiCall()
    data["stats"] = await getGameStatsApiCall(); 


    return data
  }

  const gamePageLoader = async ({params}) => {
    const response = await gameFullInfoApiCall(params.gameId)
    if (response.ok) {
      return response.json();
    }
    else {
      return redirect("*")
    }
  }

  const loginPageLoader = async () => {
    if (loggedIn) {
        return redirect("/")
    }
    return null
  }

  const logoutPageLoader = async () => {
    localStorage.removeItem("token")
    return redirect("/")
  }

  const loginCheck = async () => {
    const token = localStorage.getItem("token")
    if (token != null) {
      setLoggedIn(true)
    }
    else {
      setLoggedIn(false)
    }
  }

  const loginPageAction = async ({request, params}) => {
    const formData = await request.formData();
    const response = await loginApiCall(formData)

    if (response.status === 200) {
      const parsed_response =  await response.json()
      localStorage.setItem("token", parsed_response["token"])
      setLoggedIn(true)
      return redirect("/")
    }
    else if (response.status === 400) {
      const response_json = await response.json()
      return {error: {status: 400, response_json}}
    }

    return null
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home loggedIn={loggedIn}/>,
      loader: homePageLoader,
      errorElement: <DefaultFallBack/>
    },
    {
      path: "/memory-card",
      element: <MemoryCard loggedIn={loggedIn}/>,
      errorElement: <DefaultFallBack/>

    },
    {
      path: '/memory-card/:gameId',
      element: <Game loggedIn={loggedIn} />,
      loader: gamePageLoader,
      errorElement: <DefaultFallBack/>
    },
    {
      path: "/login",
      element: <Login/>,
      loader: loginPageLoader,
      action: loginPageAction,
      errorElement: <DefaultFallBack/>
    },
    {
      path: "/logout",
      element: <div className="logout">Hopefully you didnt see this</div>,
      loader: logoutPageLoader,
      action: loginPageAction,
      errorElement: <DefaultFallBack/>
    }, 
    {
      path: '*',
      element: <NotFound/>,
      errorElement: <DefaultFallBack/>
    }
  ]);

  useEffect(() => {
    loginCheck()
  }, [])

  return (
    <div className="MainBackground">
        <RouterProvider router={router}/>
    </div>
    
  );    
}

export default App;
