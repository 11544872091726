import { useEffect, useState } from 'react';
import GameCard from './GameCard';
import GameDisplay from './GameDisplay';
import NewGame from './NewGame.jsx';
import {gamesListApiCall, gameDetailsApiCall, gameImagesApiCall, deleteImageApiCall } from '../../api/MemoryCard.js' 
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../Components/ConfirmDialog.jsx';

export default function MemoryCard(props) {
    const [gamelist,  setGamelist] = useState([])
    const [filteredGameList, setFilteredGameList] = useState([])
    const [selectedGame, setSelectedGame] = useState([])
    const [selectedGameImages, setSelectedGameImages] = useState([])
    const [listType, setListType] = useState([])
    const [newGamePanelVisible, setNewGamePanelVisible] = useState(false)
    const [searchInput, setSearchInput] = useState("")
    const [fullSizeImageModalVisible, setFullSizeImageModalVisible] = useState("")
    const [imageModalSource, setImageModalSource] = useState("")
    const [showImageDeleteDialog, setShowImageDeleteDialog] = useState(false)

    const list_types = {
        "completed": "Completed",
        "continuous": "Continuous",
        "backlog": "Backlog",
        "wish_list": "Wish List",
        "dropped": "Dropped",
        "all": "All"
    }

    const loggedIn = props.loggedIn

    async function loadTab(list_type) {
        var gl = await getGamesList(list_type)
        await getGameDetails(gl[0].id)
    }
 
    async function getGamesList(list_type) {
        if (list_type == null) {
            list_type = "completed"
            
        }
        document.title = `Loople - ${list_types[list_type]}`
        setListType(list_type)
        
        const games_list = await gamesListApiCall(list_type)
        setGamelist(games_list)
        setSearchInput('')
        setFilteredGameList(games_list)

        return games_list
    }

    async function getGameDetails(game_id) { 
        let selected_game = await gameDetailsApiCall(game_id)

        setSelectedGame(selected_game)

        let images = await gameImagesApiCall(game_id)
        
        if( images.length === 0 ){
            images = [{
                image: "loople_blank.png",
                alt_text: "placeholder image showing the words blank",
                title: "placeholder image"
            }]
        }

        setSelectedGameImages(images)
    }

    async function refreshImageList() {
        let images = await gameImagesApiCall(selectedGame.id)
        
        if( images.length === 0 ){
            images = [{
                image: "loople_blank.png",
                alt_text: "placeholder image showing the words blank",
                title: "placeholder image"
            }]
        }

        setSelectedGameImages(images)
        return images.length
    }

    async function refreshGameList() {
        getGamesList(listType)
    }

    async function refreshGameListAndResetSelectedGame() {
        var gl = await getGamesList(listType)
        await getGameDetails(gl[0].id)
    }

    async function refreshForStatusChange() {
        if (listType === 'all' || listType === 'completed' || listType === 'continuous') {
            await getGameDetails(selectedGame.id)
        }
        else {
            var gl = await getGamesList(listType)
            await getGameDetails(gl[0].id)
        }
    }

    async function refreshSelectedGame(game_id) {
        getGameDetails(game_id)
    }

    async function updateFilteredList(filter) {
        const filteredList = gamelist.filter((game) =>  {
            return filter === "" || game.name.toLowerCase().includes(filter.toLowerCase())
        })
        setFilteredGameList(filteredList)
    }

    async function updateSearch(element) {
        setSearchInput(element.target.value)
        updateFilteredList(element.target.value)
    }

    async function clearSearchBar()  {
        setSearchInput('')
        updateFilteredList('')
    }

    async function updateFullSizeImageModal(image) {
        setFullSizeImageModalVisible(true)
        setImageModalSource(image)
    }

    async function closeModal() {
        setFullSizeImageModalVisible(false)
        setShowImageDeleteDialog(false)
    }

    async function deleteImage() {
        setFullSizeImageModalVisible(false)
        setShowImageDeleteDialog(false)
        await deleteImageApiCall(imageModalSource.id)
        refreshImageList()
    }

    const gameWindowTabs = Object.entries(list_types).map(([value, text]) => {
        return (
            <button key={value} className={listType === value ? "active": undefined} onClick={() => loadTab(value)}>{text}</button>
        )
    })

    useEffect(() => {
        loadTab()
        // fix this warning with useCallbacks probably
        // eslint-disable-next-line
    }, [])

    return (
        <div className='Games'>
            <div className='game-window'>
                <div className='game-window-tabs'>
                    <Link to={'/'}><i className='back-arrow icon-size-32 white-filter'/></Link>
                    {gameWindowTabs}
                </div>
                <div className='panels'>
                    <div className='game-list-panel'>
                        <div className='search-bar'>
                            <input name='search' value={searchInput} placeholder='Search Game...' onChange={e => updateSearch(e)}/>
                            <button className='clear' onClick={() => clearSearchBar()}>clear</button>
                                {loggedIn && <button className={"add" +(newGamePanelVisible? " active":"")} onClick={() => setNewGamePanelVisible(~newGamePanelVisible)} ><i className="plus icon-size-16 white-filter"/></button>}
                        </div>
                        <div className='game-list-items'>
                            {filteredGameList && filteredGameList.map( (game) => {
                                    return (<GameCard game={game} listType={listType} onClick={getGameDetails} key={game.id} active={selectedGame.id===game.id}/>)
                            })}
                        </div>
                    </div>
                    {!!newGamePanelVisible && 
                        <div className='create-game-panel-shadow'>   
                            <div className='create-game-panel'>
                                <NewGame setPanelVisible={setNewGamePanelVisible} refreshGameList={refreshGameList}/>
                            </div>
                        </div>
                    }
                    {!!fullSizeImageModalVisible && 
                        <div className='modal-wrapper' onClick={()=> closeModal()}>
                            <div className='full-size-image-modal' onClick={e => e.stopPropagation()}>
                                <img src={imageModalSource.image}/>
                                {!!loggedIn && imageModalSource.id &&
                                    <div className='modal-buttons'>
                                        <button className='negative-button' onClick={()=> setShowImageDeleteDialog(~showImageDeleteDialog)}>
                                            Delete Image
                                        </button>
                                        <ConfirmDialog visible={showImageDeleteDialog} functionOnConfirm={deleteImage} setVisible={setShowImageDeleteDialog}/>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div className='game-display-panel'>
                        <GameDisplay key={selectedGame.id} game={selectedGame} images={selectedGameImages} 
                            refreshSelectedGame={refreshSelectedGame} 
                            refreshGameListAndResetSelectedGame={refreshGameListAndResetSelectedGame}
                            refreshForStatusChange={refreshForStatusChange}
                            updateFullSizeImageModal={updateFullSizeImageModal}
                            refreshImageList={refreshImageList}
                            loggedIn={loggedIn} listType={listType}
                        />
                    </div>
                </div>
            </div>
        </div>
    )    
}