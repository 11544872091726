import { useState } from "react"
import { patchGameApiCall } from "../api/MemoryCard";




export default function TransitionStatus(props) {
    const [showDialog, setShowDialog] = useState(false)
    const [field1value, setfield1value] = useState("")
    const [field2value, setfield2value] = useState("")



    let buttonText;
    let field;
    let field1Name;
    let field2Name;
    let nextStatus;

    async function updateValue(element, setValue) {
        setValue(element.target.value)
    }

    async function transitionStatus() {

        let data = {
            status: nextStatus
        }

        if (field1Name && field1value) {
            data[field1Name] = field1value
        }

        if (field2Name && field2value) {
            data[field2Name] = field2value
        }

        if (data.status === "P" || (data.status === "C" && !props.currently_playing) ) {
            data["currently_playing"] = true
        }
        else if ((data.status === "C" && props.currently_playing) || data.status === "H") {
            data["currently_playing"] = false
        }

        await patchGameApiCall(props.gameId, data)
        props.reloadFunction()

        setShowDialog(false)
    }

    switch (props.currentStatus) {
        case 'C':
            field = <></>
            nextStatus = 'C'
            if (!props.currently_playing) {
                buttonText = "Playing?"
            }
            else{
                buttonText = "Stopped"
            }
            break;
        case 'P':
            buttonText = "Change Status"
            nextStatus = 'C'
            let possibleStatus = {
                'C': 'Completed',
                'H': 'Hold',
                'D': 'Dropped'
            }
            field = <>
                <select  value={field1value}  onChange={e => updateValue(e, setfield1value)}>
                    {Object.entries(possibleStatus).map(([value, text]) =>  {
                        return <option value={value}>{text}</option>
                    })}
                </select>
            </>
            field1Name = 'status'
            break;
        case 'H':
            if (!props.currently_playing) {
                buttonText = "Again?"
                nextStatus = 'P'
            }
            else {
                console.error("error this should not run")
            }
            field = <></>
            break;
        case 'B':
            buttonText = "Playing?"
            field1Name = "date_started"
            nextStatus = 'P'
            field = <>
                <label>Date Started</label>
                <input type="date" value={field1value}  onChange={e => updateValue(e, setfield1value)}/>
            </>
            break;
        case 'D':
            buttonText = "Undrop?"
            nextStatus = 'P'
            field = <></>
            break;
        case 'W':
            buttonText = "Purchased?"
            field1Name = "date_purchased"
            field2Name = "price_when_purchased"
            nextStatus = 'B'

            field = <>
                <label>Date Purchased</label>
                <input type="date" value={field1value}  onChange={e => updateValue(e, setfield1value)}/>
                <label>Purchased for</label>
                <input type="number" step="0.01" value={field2value}  onChange={e => updateValue(e, setfield2value)}/>
            </>
            break;
    }


    
    return (
        <div className='transition-div'>
            {!!showDialog &&
            <div className="transition-dialog">
                <p>Change Status?</p>
                {!!field && field}
                <button onClick={() => transitionStatus()}>Confirm</button>
            </div>
            }
            <button className={`transition-toggle secondary-button ${!!showDialog && ' active'}`} onClick={() => setShowDialog(~showDialog)}>{buttonText}</button>
        </div>
        
    )
}