import { useState } from 'react';
import GameDisplayField from '../../Components/GameDisplayField';
import { deleteFromQueueApiCall, deleteGameApiCall, queueAddAPiCall, patchGameApiCall } from '../../api/MemoryCard.js' 
import GameDisplayCarousel from '../../Components/GameDisplayCarousel.jsx';
import {formatDate} from '../../helpers/date_helper.js'
import ConfirmDialog from '../../Components/ConfirmDialog.jsx';
import TransitionStatus from '../../Components/TransitionStatus.jsx';
import { GAME_STATUS } from '../../data/gameStatus.js';
import { Link } from 'react-router-dom';


export default function GameDisplay(props) {
    const [editMode, setEditMode] = useState(false)

    const [rating, setRating] = useState("")
    const [dateStarted, setDateStarted] = useState("")
    const [dateCompleted, setDateCompleted] = useState("")
    const [datePurchased, setDatePurchased] = useState("")
    const [priceWhenPurchased, setPriceWhenPurchased] = useState("")
    const [totalHoursPlayed, setTotalHoursPlayed] = useState("")
    const [hoursWhenCompleted, setHoursWhenCompleted] = useState("")
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [lastplayed, setLastPlayed] = useState("")

    let game = props.game
    let imageList = props.images
    
    if (!game) {
        game = {name:''}
    }

    function changeEditMode(value) {
        setEditMode(value)
        if (value){ 
            setRating(props.game.rating)
            setDateStarted(props.game.date_started)
            setDateCompleted(props.game.date_completed)
            setDatePurchased(props.game.date_purchased)
            setPriceWhenPurchased(props.game.price_when_purchased)
            setTotalHoursPlayed(props.game.total_hours_played)
            setHoursWhenCompleted(props.game.hours_when_completed)
            setLastPlayed(props.game.last_played?.slice(0,-4))
        }

    }

    async function submitChanges() {
        var data = {
            name:props.game.name,
            status:props.game.status,
            rating:rating,
            date_started:dateStarted? dateStarted : null,
            date_completed:dateCompleted? dateCompleted : null,
            date_purchased:datePurchased? datePurchased : null,
            price_when_purchased:priceWhenPurchased,
            total_hours_played:totalHoursPlayed,
            hours_when_completed:hoursWhenCompleted,
            last_played: lastplayed? lastplayed : null
        }
        await patchGameApiCall(props.game.id, data)
        setEditMode(false)
        props.refreshSelectedGame(props.game.id)
    }

    async function deleteEntry() {
        await deleteGameApiCall(props.game.id)
        setShowDeleteDialog(false)
        props.refreshGameListAndResetSelectedGame()
    }

    async function updateQueueStatus() {
        if (props.game.queue == null) {
            await queueAddAPiCall(props.game.id)
        }
        else {
            await deleteFromQueueApiCall(props.game.queue)
        }

        props.refreshSelectedGame(props.game.id)
    }

    return (
        <div className='game-display'>
            <div className='header-shadow'>
                <h1>{game.name}</h1>
            </div>
            {
                imageList.length > 0 &&
                <GameDisplayCarousel 
                    imageList={imageList} gameId={props.game.id} refreshImageList={props.refreshImageList}
                    updateFullSizeImageModal={props.updateFullSizeImageModal}  loggedIn={props.loggedIn}
                />
            }
            <div className='row'>
                <GameDisplayField label="Rating:" display={game.rating} editMode={editMode} value={rating} setValue={setRating} dataType="number"/>
                {((game.continuous || props.listType === 'all') && game.status != 'H' ) && <GameDisplayField label="Playing:" display={game.currently_playing} dataType="text"/>}
                {(props.listType === 'backlog' && game.status === 'H') && <GameDisplayField label="On Hold:" display={true} dataType="text"/>}
            </div>
            <div className='row'>
                <GameDisplayField label="Date Started:" display={formatDate(game.date_started, 'shortDayFullMonth')} editMode={editMode} value={dateStarted} setValue={setDateStarted} dataType="date"/>
                <GameDisplayField label="Date Completed:" display={formatDate(game.date_completed, 'shortDayFullMonth')} editMode={editMode} value={dateCompleted} setValue={setDateCompleted} dataType="date"/>
            </div>
            <div className='row'>
                <GameDisplayField label="Date Purchased:" display={formatDate(game.date_purchased, 'shortDayFullMonth')} editMode={editMode} value={datePurchased} setValue={setDatePurchased} dataType="date"/>
                <GameDisplayField label="Purchased For:" display={"£" + game.price_when_purchased} editMode={editMode} value={priceWhenPurchased} setValue={setPriceWhenPurchased} dataType="number"/>
            </div>
            <div className='row'>
                <GameDisplayField label="Playtime:" display={game.total_hours_played  + " Hours"} editMode={editMode} value={totalHoursPlayed} setValue={setTotalHoursPlayed} dataType="number"/>
                <GameDisplayField label="Completed Time:" display={game.hours_when_completed + " Hours"} editMode={editMode} value={hoursWhenCompleted} setValue={setHoursWhenCompleted} dataType="number"/>
            </div>
            {game.continuous &&
                <div className='row'>
                    <GameDisplayField label="Last Played:" display={formatDate(game.last_played, 'shortDayFullMonth')} editMode={editMode} value={lastplayed} setValue={setLastPlayed} dataType="datetime-local"/>
                </div>
            }
            {
                props.listType === 'all' &&
                <div className='row'>
                    <GameDisplayField label="Status" display={GAME_STATUS[game.status]}/>
                </div>
            }
            <div className='action-buttons'>
                    {
                        props.loggedIn && !editMode && <>
                                <ConfirmDialog visible={showDeleteDialog} functionOnConfirm={deleteEntry} setVisible={setShowDeleteDialog}/>
                                <button onClick={() => setShowDeleteDialog(~showDeleteDialog)} className={`negative-button ${!!showDeleteDialog && ' active'}`}>
                                    Delete Entry
                                </button> 
                            </>
                    }
                    {
                        props.loggedIn && !editMode && <>
                            <button onClick={() => updateQueueStatus()}>
                                {props.game.queue? 'Remove From Queue': 'Add To Queue'}
                            </button>
                        </>
                    }
                    {
                        props.loggedIn  && !editMode && <>
                            <TransitionStatus 
                                reloadFunction={props.refreshForStatusChange} gameId={props.game.id}
                                currentStatus={props.game.status} currently_playing={props.game.currently_playing}
                            />
                        </>
                    }
                    {
                        props.loggedIn && !editMode && 
                        <button onClick={() => changeEditMode(true)}  className='secondary-button'>
                            Edit Entry
                        </button>
                    }
                    {
                        !editMode && 
                            <Link to={`./${game.id}`}>
                                <button className='link-button'> More Info</button> 
                            </Link>
                        
                    }
                    {
                        props.loggedIn && editMode && 
                        <>
                            <button onClick={() => changeEditMode(false)}  className='negative-button'>
                                Cancel
                            </button>
                            <button onClick={() => submitChanges()}  className='save-button'>
                                Submit
                            </button>
                        </>
                    }
            </div>
        </div>
    )
}